'use client'

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Image from 'next/image'
import { Hepta_Slab } from 'next/font/google'
import { useRouter } from 'next/navigation'
import { ArrowRight } from 'lucide-react'
import Link from 'next/link'
import dynamic from 'next/dynamic'

const heptaSlab = Hepta_Slab({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  display: 'swap',
  preload: true,
  fallback: ['system-ui', 'arial'],
})

const popularProjects = [
  {
    name: 'Renovare',
    href: '/servicii/renovari-interioare'
  },
  {
    name: 'Construcție',
    href: '/servicii/constructii'
  },
  {
    name: 'Design Interior',
    href: '/servicii/finisaje'
  },
  {
    name: 'Grădinărit',
    href: '/servicii/exterioare/gradinarit'
  }
]

const MotionDiv = dynamic(() => import('framer-motion').then(mod => mod.motion.div), {
  ssr: false
})

// Custom hook pentru detectarea dimensiunii ecranului
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  
  return windowSize;
}

export default function Hero() {
  const router = useRouter()
  const [selectedTrade, setSelectedTrade] = useState('')
  const [particles, setParticles] = useState([])
  const { width } = useWindowSize();
  const isMobile = width ? width < 768 : false; // 768px este breakpoint-ul pentru mobil

  const generateParticles = useCallback((count) => {
    return Array.from({ length: count }, () => ({
      width: Math.random() * 5,
      height: Math.random() * 5,
      left: Math.random() * 100,
      top: Math.random() * 100,
      animationDuration: Math.random() * 5 + 3,
      animationDelay: Math.random() * 3,
    }))
  }, [])

  const memoizedParticles = useMemo(() => generateParticles(50), [generateParticles])

  useEffect(() => {
    setParticles(memoizedParticles)
  }, [memoizedParticles])



  const handleTradeSelect = (trade) => {
    setSelectedTrade(trade)
  }

  const handleNext = () => {
    if (selectedTrade) {
      router.push(`/new-post?trade=${encodeURIComponent(selectedTrade)}`)
    }
  }

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <section className={`relative overflow-hidden text-white h-[90vh] flex items-center justify-center ${heptaSlab.className}`}>
      {/* Background Images */}
      <div className="absolute inset-0">
        <Image
          src="/hero.webp"
          fill
          className="object-cover hidden md:block"
          alt="Hero background desktop"
          priority
          placeholder="blur"
          blurDataURL="data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAAQAAAACQAAAwAAQUxQSBIAAAABF6AmABQbp7cjIiIRAQAAABBXUDggGAAAADABAJ0BKgoABAABQCYlpAADcAD++/1QAA=="
          sizes="100vw"
          quality={90}
          loading="eager"
        />
        <Image
          src="/mobHero.png"
          fill
          className="object-cover block md:hidden"
          alt="Hero background mobile"
          priority
          placeholder="blur"
          blurDataURL="data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAAQAAAACQAAAwAAQUxQSBIAAAABF6AmABQbp7cjIiIRAQAAABBXUDggGAAAADABAJ0BKgoABAABQCYlpAADcAD++/1QAA=="
          sizes="100vw"
          quality={90}
          loading="eager"
        />
        {/* Overlay gradient pentru contrast mai bun */}
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-black/30" />
        {/* Overlay suplimentar pentru contrast uniform */}
        <div className="absolute inset-0 bg-black/20" />
      </div>

      {/* Restul conținutului */}
      <div className="relative z-10 w-full max-w-6xl mx-auto px-4">
        <div className="flex flex-col lg:flex-row w-full items-center">
          {/* Conținut partea stângă */}
          <div className="w-full lg:w-1/2 mb-12 lg:mb-0 text-center lg:text-left">
            <div className="flex items-center justify-center lg:justify-start gap-2 mb-4">
              <div className="w-2 h-2 rounded-full bg-white"></div>
              <span className="text-sm font-medium text-white">
                Platforma Meșterilor Profesioniști
              </span>
            </div>

            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white mb-6">
              Descoperă <span className="text-gray-200">Meseriașii</span> pentru casa ta
            </h1>

            <p className="text-lg text-gray-100 mb-8 max-w-2xl">
              Conectăm talente excepționale cu proiecte extraordinare. 
              Transformă-ți ideile în realitate!
            </p>
              
            {/* Input și buton de căutare */}
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full max-w-2xl mx-auto lg:mx-0 mb-8">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Ex: Electrician, Instalator, Zugrav"
                  className="w-full h-11 px-6 rounded-md border border-gray-300 bg-white/90 backdrop-blur-sm
                           text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 
                           focus:ring-white focus:border-transparent transition-all"
                  onChange={(e) => handleTradeSelect(e.target.value)}
                />
              </div>
              <button
                onClick={handleNext}
                className="w-full sm:w-auto inline-flex items-center justify-center h-11 px-8 
                         font-medium text-gray-900 bg-white hover:bg-gray-100 
                         transition-colors rounded-md"
              >
                Continua <ArrowRight className="ml-2 w-5 h-5" />
              </button>
            </div>
              
            {/* Proiecte populare */}
            <MotionDiv
              variants={variants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.4, delay: 0.3 }}
            >
              <p className="mb-3 font-medium text-white">Proiecte Populare:</p>
              <div className="flex flex-wrap justify-center lg:justify-start gap-6">
                {popularProjects.map((project, index) => (
                  <MotionDiv
                    key={index}
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.4, delay: 0.4 + index * 0.1 }}
                  >
                    <Link 
                      href={project.href}
                      className="bg-white/90 backdrop-blur-sm text-gray-900 px-6 py-2 
                               rounded-md text-sm hover:bg-white transition-all duration-300"
                    >
                      {project.name}
                    </Link>
                  </MotionDiv>
                ))}
              </div>
            </MotionDiv>
          </div>

          {/* Placeholder pentru WorkerSlider */}
          <div className="w-full lg:w-1/2">
            {/* Aici poate fi adăugat un WorkerSlider component */}
          </div>
        </div>
      </div>
    </section>
  )
}